import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo',
  standalone: true,
  pure: false,
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value?: string): string {
    if (!value) return '';

    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - new Date(value).getTime()) / 1000);

    if (diffInSeconds < 60) {
      return this.translate.instant('time.just-now');
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return this.translate.instant('time.minutes-ago', { count: minutes });
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return this.translate.instant('time.hours-ago', { count: hours });
    } else if (diffInSeconds < 172800) {
      return this.translate.instant('time.yesterday');
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return this.translate.instant('time.days-ago', { count: days });
    }
  }
}
